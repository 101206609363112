// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "stylesheets/application.scss"

Turbolinks.start()
ActiveStorage.start()

window.addEventListener("load", function() {
  var copiers = document.querySelectorAll("[data-copier]");
  var copier, target, buttonText;

  for (var i = 0; i < copiers.length; i++) {
    copier = copiers[i];
    target = document.querySelector(copier.getAttribute("data-target"));
    buttonText = copier.innerText;

    copier.addEventListener("click", function() {
      navigator.clipboard.writeText(target.innerText);

      copier.innerText = "✅";

      window.setTimeout(function() {
        copier.innerText = buttonText;
      }, 3000);
    });
  }
});
